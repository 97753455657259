<template>
  <div class="homepage bjd-center">
    <b-container flui class="text-light text-center">
      <b-row align-v="center" align-h="center">
        <b-col sm="2">
          <b-link href="//www.linkedin.com/in/bjd145/" v-b-tooltip.hover title="My professional history.">
            <b-img src="../assets/linkedin.png" fluid alt="LinkedIn" rounded width="150" height="150" />
          </b-link>
        </b-col>
        <b-col sm="2">
          <b-link href="//github.com/briandenicola/" v-b-tooltip.hover title="My portfolio of code samples and projects.">
            <b-img src="../assets/github.png" fluid alt="GitHub" rounded width="150" height="150" />
          </b-link>
        </b-col>
        <b-col sm="2">
          <b-link href="//twitter.com/brianjdenicola" v-b-tooltip.hover title="My random musing on computers and history.">
            <b-img src="../assets/twitter.png" fluid alt="Twitter" rounded width="150" height="150" />
          </b-link>
        </b-col>
        <b-col sm="2">
          <b-link href="//https://mastodon.social/@brianjdenicola" v-b-tooltip.hover title="My random musing on computers and history - just on an open platform.">
            <b-img src="../assets/mastodon.png" fluid alt="Twitter" rounded width="150" height="150" />
          </b-link>
        </b-col>
        <b-col sm="2">
          <b-link href="//quickanddirtyscripting.wordpress.com" v-b-tooltip.hover title="My older blog but it checks out.">
            <b-img src="../assets/blog.png" fluid alt="Blog" rounded width="150" height="150" />
          </b-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
}
</script>

<style>
#homepage {
  color: white;
  background-color: black;
}

.bjd-center {
  min-height: 75%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
  align-content: center; 
  justify-content: center;
  flex-flow: column;
}
</style>

