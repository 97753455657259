<template>
  <div class="copyright">
    <b-container fluid class="text-light text-center">
      <b-row align-h="center">
        <b-col><p>Copyright &reg; 2023</p></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
}
</script>

<style>
p { 
  font-size: 8px;
}
</style>
