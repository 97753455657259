<template>
  <div id="app">
    <HomePage/>
    <CopyRight />
  </div>
</template>

<script lang="ts">
import HomePage from './components/HomePage.vue';
import CopyRight from './components/CopyRight.vue';

export default {
  name: 'App',
  components: {
    HomePage,
    CopyRight,
  }
}
</script>

<style>
html { 
  background: black;
  color: white;
}

#app { 
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
  background: black;
  color: white;
}
</style>
